<template>
	<div>
		<a-spin :spinning="loading">
			<a-row>
				<a-col :span="18">
					<a-button @click="onSearchBtn">
						<template #icon><Icon icon="SearchOutlined"/></template>
						查询</a-button>
					<exportModal class="ui-exportBrn" v-permission="['pc_system_log_export']" :type="type" :searchData="selIdsData" :condition="condition" :role="role" :isAll="1">
						<a-button>
							<template #icon><Icon icon="UploadOutlined"/></template>
							导出</a-button>
					</exportModal>
					<exportModal class="ui-exportBrn" v-permission="['pc_system_log_export']" style="margin-right: 16px;" :type="type" :searchData="searchData" :condition="condition" :role="role" :isAll="0">
						<a-button>
							<template #icon><Icon icon="LogoutOutlined"/></template>
							导出全部</a-button>
					</exportModal>
					<a-button @click="onSearch">
						<template #icon><Icon icon="ReloadOutlined"/></template>
						刷新</a-button>
					<a-button @click="onSort">
						<template #icon><Icon icon="OrderedListOutlined"/></template>
						排序</a-button>
				</a-col>
				<a-col :span="6" style="text-align: right;">
					<!-- <a-button type="primary">新增单位</a-button> -->
				</a-col>
			</a-row>
			
			<div v-show="isSearch">
				<a-form class="label-width ui-form" ref="formRef" :model="formModal" layout="inline">
					<a-form-item label="操作人单位" class="ui-form__item">
						<a-select  allow-clear v-model:value="formModal.unitId" style="width: 190px;" placeholder="请选择操作人单位">
							<a-select-option v-for="(item, index) in $store.state.count.unitList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item label="操作人部门" class="ui-form__item">
						<a-select  allow-clear v-model:value="formModal.depId" style="width: 190px;" placeholder="请选择操作人部门">
							<a-select-option v-for="(item, index) in $store.state.count.departmentList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item label="操作人姓名" class="ui-form__item">
						<a-input v-model:value="formModal.nickName" placeholder="请输入操作人姓名"></a-input>
					</a-form-item>
					
					<a-form-item label="日志描述" class="ui-form__item">
						<a-input v-model:value="formModal.description" placeholder="请输入日志描述"></a-input>
					</a-form-item>
					
					<a-form-item label="操作时间" class="ui-form__item">
						<a-range-picker show-time v-model:value="clearTime"></a-range-picker>
					</a-form-item>
					<div style="text-align: center;">
						<a-button @click="onReset">重置</a-button>
						<a-button type="primary" @click="onSearch">搜索</a-button>
					</div>
				</a-form>
			</div>
			
			<div v-show="isSort" class="ui-form" style="padding-bottom: 20px;">
				<a-row style="align-items: center;">
					<a-col :span="18">
						<a-checkbox-group v-model:value="sortList">
							<a-checkbox :value="'u.name'">操作人单位</a-checkbox>
							<a-checkbox :value="'d.name'">操作人部门</a-checkbox>
							<a-checkbox :value="'up.nickname'">操作人姓名</a-checkbox>
							<a-checkbox :value="'al.operation'">日志描述</a-checkbox>
							<a-checkbox :value="'al.create_time'">操作时间</a-checkbox>
						</a-checkbox-group>
					</a-col>
					<a-col :span="6" style='text-align: right;'>
						<a-button type="primary" @click="onSortClick('asc')">升序</a-button>
						<a-button type="primary" @click="onSortClick('desc')">降序</a-button>
						<a-button type="primary" @click="onResetSortClick()">重置</a-button>
					</a-col>
				</a-row>
			</div>
			
			<div style="margin-top: 20px;">
				<c-Table ref="cTable" :columns="columns" :isRequest="true" :requestFun="getDataFun" rowSelectionType="checkbox" :searchData="searchData" rowKey="id" @rowSelected="rowSelected" :scroll="{ x: 1500 }" bordered draggable>
					<template #bodyCell="{ column, record, index, pagination }">
						<template v-if="column.key === 'index'">
							{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
						</template>
						<template v-if="column.key === 'time'">
							{{ transDateTime(record.time) }}
						</template>
						<template v-if="column.key === 'position'">
							<div>{{ record.position }}</div>
							<!-- <div v-if="record.position == 1">科长</div>
							<div v-if="record.position == 2">主任</div>
							<div v-if="record.position == 3">股长</div>
							<div v-if="record.position == 4">局长</div>
							<div v-if="record.position == 5">副局长</div> -->
						</template>
					</template>
				</c-Table>
				<!-- <a-table rowKey="id" class="table"
					:columns="columns" 
					:dataSource="list" 
					:pagination="pagination" bordered 
					size="small"
					:scroll="{ x: 1500 }"
				>
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'index'">
							{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
						</template>
						<template v-if="column.key === 'time'">
							{{ transDateTime(record.time) }}
						</template>
						<template v-if="column.key === 'position'">
							<div v-if="record.position == 1">科长</div>
							<div v-if="record.position == 2">主任</div>
							<div v-if="record.position == 3">股长</div>
							<div v-if="record.position == 4">局长</div>
							<div v-if="record.position == 5">副局长</div>
						</template>
					</template>
				</a-table> -->
			</div>
		</a-spin>
	</div>
</template>

<script>
	import { Icon } from '@/components/icon/icon.js';
	import { getLog } from '@/service/modules/system.js';
	import cTable from '@/components/cTable/index.vue';
	import exportModal from '@/components/exportModal/exportModal.vue';
	export default {
		components: { Icon, cTable, exportModal },
		data() {
			return {
				loading: false,
				isSearch: false,
				isSort: false,
				isExpand: false,
				searchData: {},
				formModal: {},
				list: [],
				type: 'logMangerStrategy', // 导出类型
				role: 1, // 导出角色
				condition: '3', // 导出条件
				selIdsData: {},
				sortList:[],
				getDataFun: getLog,
				clearTime: [], // 存储时间 用来清空重置
				columns: [{
					title: '行号',
					align: 'center',
					key: 'index'
				}, {
					title: '操作人姓名',
					align: 'center',
					dataIndex: "username"
				}, {
					title: '操作人手机号',
					align: 'center',
					dataIndex: "phone"
				}, {
					title: '操作人角色',
					align: 'center',
					dataIndex: "roleName"
				}, {
					title: '操作人职位',
					align: 'center',
					key: 'position',
					// dataIndex: "position"
				}, {
					title: '操作人部门',
					align: 'center',
					dataIndex: "depName"
				}, {
					title: '操作人单位',
					align: 'center',
					dataIndex: "unitName"
				}, {
					title: '操作时间',
					align: 'center',
					// key: 'createTime'
					key: 'time'
				}, {
					title: '日志描述',
					align: 'center',
					dataIndex: "description"
				}],
			}
		},
		created() {
			this.onSearch()
		},
		methods: {
			// async getData() {
			// 	this.loading = true;
			// 	try {
			// 		let ret = await getLog({
			// 			page: this.pagination.current,
			// 			pageSize: this.pagination.pageSize,
			// 			...this.searchData
			// 		});
			// 		this.loading = false;
			// 		if (ret.code === 200) {
			// 			console.log("log",ret)
			// 			this.list = ret.data.list;
			// 			this.pagination.total = ret.data.totalCount;
			// 		}
			// 	} catch(e) {
			// 		this.loading = false;
			// 	}
			// },
			onReset() {
				this.$refs.formRef.resetFields();
				this.formModal = {}
				this.clearTime = []
				this.onSearch();
			},
			onSearch() {
				this.searchData = JSON.parse(JSON.stringify(this.formModal));
				if (this.clearTime && this.clearTime.length) {
					this.searchData.startTime = this.clearTime[0].unix();
					this.searchData.endTime = this.clearTime[1].unix();
				}
				// this.pagination.current = 1;
				// this.getData();
				this.$nextTick(() => {
					this.$refs.cTable.toQuery();
				})
			},
			onSearchBtn() {
				this.isSearch = !this.isSearch;
			},
			onSort() {
				this.isSort = !this.isSort;
			},
			rowSelected(item) {
				this.selIdsData.ids = item.keys.join(',')
			},
			onChangeFun(date, dateString) { // 时间变化的回调
			  this.clearTime = date
			  let starDate = new Date(dateString[0])
			  let endDate = new Date(dateString[1])
			  // console.log(starDate.valueOf(), endDate.valueOf());
			  this.formModal.startTime = starDate.valueOf()/1000
			  this.formModal.endTime = endDate.valueOf()/1000
			},
			onSortClick(type){
				let list = [];
				this.sortList.forEach(element => {
					list.push({key:element,sort:type});
				});
				this.formModal.sort = list;
				console.log(this.formModal);
				this.onSearch();
			},
			onResetSortClick(){
				this.sortList = [];
				this.formModal.sort = undefined;
				this.onSearch();
			}
		}
	}
</script>

<style scoped>
	.ui-form {
		margin-top: 20px;
		padding: 20px 20px 0;
		background: #F5F7FA;
	}
	.ui-form__middle {
		padding-top: 4px;
		text-align: center;
	}
	.ui-exportBrn {
		margin-left: 16px;
	}
</style>